import React from "react";
import { Link } from "react-router-dom";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { References, SVGIcon } from "@micado-digital/react-intown-core";

const useStyles = makeStyles((theme) => ({
	root: {
		background: theme.palette.background.light,
		position: "relative",
		"&::before": {
			background: theme.palette.background.light,
			content: "''",
			height: "100%",
			left: -4000,
			position: "absolute",
			top: 0,
			width: 8000,
			zIndex: "-1"
		}
	},
	item: {
		background: theme.palette.primary.main,
		display: "block",
		padding: theme.spacing(3, 2),
		textAlign: "center",
		"&:hover": {
			background: theme.palette.secondary.main
		}
	},
	icon: {
		color: "#FFFFFF"
	},
	iconWrapper: {
		alignItems: "center",
		display: "flex",
		height: 45,
		justifyContent: "center",
		lineHeight: 45
	},
	title: {
		color: "#FFFFFF",
		marginTop: theme.spacing(1.5)
	}
}));

const LinkIconTileItem = (props) => {
	const { icon, title, addition, pagename } = props.data;
	const css = useStyles();

	if (addition === "_blank") {
		return (
			<Grid item xs>
				<a className={css.item} href={pagename} target="_blank" rel="noreferrer">
					<div className={css.iconWrapper}>
						<SVGIcon className={css.icon} src={icon} size={45}></SVGIcon>
					</div>
					<Typography className={css.title} variant="h3">
						{title}
					</Typography>
				</a>
			</Grid>
		);
	} else {
		return (
			<Grid item xs>
				<Link className={css.item} to={pagename}>
					<div className={css.iconWrapper}>
						<SVGIcon className={css.icon} src={icon} size={45}></SVGIcon>
					</div>
					<Typography className={css.title} variant="h3">
						{title}
					</Typography>
				</Link>
			</Grid>
		);
	}
};

const LinkIconTile = (props) => {
	const { hasBackground, isExtended = true } = props;
	const { i18n, t } = useTranslation();
	const css = useStyles();

	const lang = i18n.language;

	const data = [
		{
			id: 0,
			title: t("site.ui.labels.weather"),
			icon: "/img/icons/weather.svg",
			pagename: lang === "de" ? "/de/wetter.html" : "/en/weather.html"
		},
		{
			id: 1,
			title: t("site.ui.labels.morningPost"),
			icon: "/img/icons/letter.svg",
			pagename:
				lang === "de" ? "https://www.walserberg.at/morgenpost.pdf" : "https://www.walserberg.at/morningmail.pdf",
			addition: "_blank"
		},
		{
			id: 2,
			title: t("site.ui.labels.wellness"),
			icon: "/img/icons/lotus.svg",
			pagename: lang === "de" ? "/de/wellness.html" : "/en/wellness.html"
		}
	];

	const links = {
		elements: [
			{
				id: 0,
				title: t("site.ui.labels.weeklyProgram"),
				icon: "/img/icons/event.svg",
				reference: {
					pageName: lang === "de" ? "/de/wochenprogramm.html" : "/en/weekly-program.html"
				}
			},
			{
				id: 1,
				title: t("site.ui.labels.wlan"),
				icon: "/img/icons/wifi.svg",
				reference: {
					pageName: lang === "de" ? "/de/wlan.html" : "/en/wlan.html"
				}
			}
		]
	};

	return (
		<>
			<Box className={hasBackground && css.root} mb={isExtended ? 0 : 4} mt={4} pb={isExtended ? 2 : 3} pt={3}>
				<Grid container spacing={1}>
					{data.map((item) => {
						return <LinkIconTileItem data={item} key={item?.id} />;
					})}
				</Grid>
			</Box>
			<References data={links} hasBackground={true} isExtended={true}></References>
		</>
	);
};

LinkIconTile.defaultProps = {
	hasBackground: false,
	isExtended: false
};

export default LinkIconTile;
