import { LayoutStandard, parseLayout as coreParseLayout } from "@micado-digital/react-intown-core";
import ContentElements from "./contentElements";

const parseLayout = ({ elements, layoutTag }) => {
	switch (layoutTag) {
		case "standard":
			return (
				<LayoutStandard socialMedia>
					<ContentElements elements={elements}></ContentElements>
				</LayoutStandard>
			);
		default:
			return coreParseLayout(layoutTag);
	}
};

export default parseLayout;
