import React, { Suspense, lazy } from "react";
import { parseElement as coreParseElement } from "@micado-digital/react-intown-core";
import LinkIconTile from "../components/link-icon-tile";
import Container from "@material-ui/core/Container";

const ReactLiftState01 = lazy(() =>
	import(/* webpackChunkName: "reactLiftState01" */ "@micado-digital/react-liftstate/ReactLiftState01")
);
const ReactPistState01 = lazy(() =>
	import(/* webpackChunkName: "reactPistState01" */ "@micado-digital/react-piststate/ReactPistState01")
);
const ReactWebcams01 = lazy(() =>
	import(/* webpackChunkName: "reactWebcams01" */ "@micado-digital/react-webcams/ReactWebcams01")
);
const ReactSnowReport01 = lazy(() =>
	import(/* webpackChunkName: "reactSnowReport01" */ "@micado-digital/react-snowreport/ReactSnowReport01")
);

const parseElement = (element, lang) => {
	const { REACT_APP_PATH } = process.env;
	const { id, tag } = element;

	switch (tag) {
		case "extension-link-icon-tile": {
			return <LinkIconTile key={id} hasBackground isExtended></LinkIconTile>;
		}
		case "mski-liftstate": {
			let client = null;
			let region = null;
			let season = null;

			element?.textcontent?.items?.filter((item) => {
				if (item.name === "client") {
					return (client = item?.text);
				}

				if (item.name === "region") {
					return (region = item?.text);
				}

				if (item.name === "season") {
					return (season = item?.text);
				}

				return null;
			});

			return (
				<Container key={element?.id} maxWidth="lg" name="liftstate" style={{ marginTop: 32 }}>
					<Suspense fallback={<></>}>
						<ReactLiftState01
							client={client}
							filter
							lang={lang}
							region={region}
							season={season}
							skeletonItems={20}
							url={`${REACT_APP_PATH}/SkigebieteManager/Micado.SkigebieteManager.Plugin.FacilityApi/ListFacilities.api`}
						/>
					</Suspense>
				</Container>
			);
		}
		case "mski-piststate": {
			let client = null;
			let region = null;
			let season = null;

			element?.textcontent?.items?.filter((item) => {
				if (item.name === "client") {
					return (client = item?.text);
				}

				if (item.name === "region") {
					return (region = item?.text);
				}

				if (item.name === "season") {
					return (season = item?.text);
				}

				return null;
			});

			return (
				<Container key={element?.id} maxWidth="lg" name="piststate" style={{ marginTop: 32 }}>
					<Suspense fallback={<></>}>
						<ReactPistState01
							client={client}
							showLegend
							lang={lang}
							region={region}
							season={season}
							skeletonItems={20}
							url={`${REACT_APP_PATH}/SkigebieteManager/Micado.SkigebieteManager.Plugin.FacilityApi/ListFacilities.api`}
						/>
					</Suspense>
				</Container>
			);
		}
		case "mski-webcams": {
			let client = null;
			let region = null;

			element?.textcontent?.items?.filter((item) => {
				if (item.name === "client") {
					return (client = item?.text);
				}

				if (item.name === "region") {
					return (region = item?.text);
				}

				return null;
			});

			return (
				<Container key={element?.id} maxWidth="lg" name="webcams" style={{ marginTop: 32 }}>
					<Suspense fallback={<></>}>
						<ReactWebcams01
							client={client}
							gridBreakpoints={{
								xs: 12,
								sm: 6,
								md: 4
							}}
							lang={lang}
							live
							overlaySize="xlarge"
							region={region}
							showLocation
							skeletonItems={12}
							url={`${REACT_APP_PATH}/SkigebieteManager/Micado.SkigebieteManager.Plugin.WebcamApi/ListWebcams.api`}
						/>
					</Suspense>
				</Container>
			);
		}
		case "mski-snowreport": {
			let client = null;
			let region = null;

			element?.textcontent?.items?.filter((item) => {
				if (item.name === "client") {
					return (client = item?.text);
				}

				if (item.name === "region") {
					return (region = item?.text);
				}

				return null;
			});

			return (
				<Container key={element?.id} maxWidth="md" name="snowreport" style={{ marginTop: 32 }}>
					<Suspense fallback={<></>}>
						<ReactSnowReport01
							client={client}
							identifierAvalanche="avalance-level"
							identifierDateLastSnowfall="date-last-snowfall"
							identifierSnowHeightValley="snow-height-valley"
							identifierSnowHeightMountain="snow-height-mountain"
							lang={lang}
							region={region}
							url={`${REACT_APP_PATH}/SkigebieteManager/Micado.SkigebieteManager.Plugin.PropertyApi/ListProperties.api`}
						/>
					</Suspense>
				</Container>
			);
		}
		default:
			return coreParseElement(element);
	}
};

export default parseElement;
